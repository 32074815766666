<template>
  <div
    class="mt-0 pa-0"
  >
    <v-card
      v-if="options.isVisibleSuggestions"
      class="mt-6 mx-3"
      color="#eee"
      elevation="0"
      :min-height="$vuetify.breakpoint.height - 185"
      style="overflow-y: auto; overflow-x: hidden;"
    >
      <v-card-text>
        <div class="field-tags-container">
          <div
            v-for="f in allFieldTags"
            :key="f.id"
            class="field-tag-add-box white"
            :class="{'used': f.inUse}"
          >
            <div>
              {{ f.name }}
              <span v-if="f.customLabel">
                [{{ f.customLabel }}]
              </span>
            </div>
            <div @click="useFieldInQuestionnaire(f)">
              <template v-if="!f.inUse">
                <v-icon
                  small
                  color="white"
                >
                  mdi-plus
                </v-icon>
                {{ $t('actions|use') }}
              </template>
              <template v-else>
                <v-hover
                  v-slot="{ hover }"
                >
                  <div
                    @click="deleteField(f)"
                  >
                    <v-icon
                      small
                      color="white"
                    >
                      {{ hover ? 'mdi-minus' : 'mdi-checkbox-marked-circle-outline' }}
                    </v-icon>
                    {{ hover ? $t('actions|remove') : $t('common|in_use') }}
                  </div>
                </v-hover>
              </template>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-card
      v-if="options.isVisibleDocuments"
      class="mt-6 mx-3"
      color="#eee"
      elevation="0"
      :min-height="$vuetify.breakpoint.height - 185"
      style="overflow-y: auto; overflow-x: hidden;"
    >
      <v-card-text>
        <div
          v-if="options.inCaseModel || options.inCase"
          class="field-tags-container"
        >
          <div
            v-for="f in uniqueWorkbenchRequiredDocs"
            :id="f.id"
            :key="f.id"
            class="field-tag-add-box"
            :class="{'used': f.inUse}"
            style="background-color: white;"
          >
            <div
              class="primary--text font-weight-medium pr-1"
            >
              {{ translateFileInputField(f) }}
            </div>
            <div @click="useFieldInQuestionnaire(f)">
              <template v-if="!f.inUse">
                <v-icon
                  small
                  color="white"
                >
                  mdi-plus
                </v-icon>
                {{ $t('actions|use') }}
              </template>
              <template v-else>
                <v-hover
                  v-slot="{ hover }"
                >
                  <div
                    @click="deleteField(f)"
                  >
                    <v-icon
                      small
                      color="white"
                    >
                      {{ hover ? 'mdi-minus' : 'mdi-checkbox-marked-circle-outline' }}
                    </v-icon>
                    {{ hover ? $t('actions|remove') : $t('common|in_use') }}
                  </div>
                </v-hover>
              </template>
            </div>
          </div>
        </div>
        <div
          v-if="options.inTemplate && rawTemplateDocs && rawTemplateDocs.length"
          class="field-tags-container"
        >
          <div
            v-for="f in rawTemplateDocs"
            :key="f.id"
            class="field-tag-add-box"
            :class="suggestionsInUseInQuestionnaire.includes(f.name) ? 'used' : ''"
          >
            <div
              class="primary--text font-weight-medium white"
            >
              <v-icon
                x-small
                color="primary"
              >
                mdi-file-outline
              </v-icon>
              {{ translateFileInputField(f) }}
            </div>
            <div @click="useFieldInQuestionnaire(f)">
              <template v-if="suggestionsInUseInQuestionnaire.includes(f.name) === false">
                <v-icon
                  small
                  color="white"
                >
                  mdi-plus
                </v-icon>
                {{ $t('actions|use') }}
              </template>
              <template v-else>
                <v-hover
                  v-slot="{ hover }"
                >
                  <div
                    @click="deleteField(f)"
                  >
                    <v-icon
                      small
                      color="white"
                    >
                      {{ hover ? 'mdi-minus' : 'mdi-checkbox-marked-circle-outline' }}
                    </v-icon>
                    {{ hover ? $t('actions|remove') : $t('common|in_use') }}
                  </div>
                </v-hover>
              </template>
            </div>
          </div>
        </div>
        <div v-if="options.inTemplate && !rawTemplateDocs.length">
          {{ $t('expressions|no_actual_required_documents') }}
        </div>
      </v-card-text>
    </v-card>

    <div class="text-center mt-2">
      <v-btn
        v-if="options.isVisibleSuggestions"
        color="primary"
        x-small
        class="pa-3"
        @click="useAllSuggestionsFieldsInQuestionnaire"
      >
        {{ $t('actions|use_all') }}
      </v-btn>

      <v-btn
        v-if="options.isVisibleDocuments"
        color="primary"
        x-small
        class="pa-3"
        @click="$emit('add-new-document-in-template-questionnaire')"
      >
        {{ $t('actions|add_document') }}
      </v-btn>
    </div>
    <!-- <v-dialog
      v-if="templateData"
      v-model="inPreview"
      scrollable
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <img
            src="@/assets/Lavvira_monogramm_logo.svg"
            height="28px"
            width="auto"
          >
          <div
            class="ml-3"
          >
            {{ $t('questionnaire|questionnaire') }}
          </div>
          <v-spacer />
          <v-btn
            icon
            @click="inPreview = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text
          class="pt-4"
          style="height: 50vh;"
        >
          <v-card
            v-if="templateData.questionnaire.title"
            min-height="100"
            outlined
            class="mx-auto mb-10 rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-lg"
          >
            <v-card-text
              class="pa-2"
            >
              {{ templateData.questionnaire.title }}
            </v-card-text>
          </v-card>
          <QuestionnaireForm
            :questionnaire="templateData.questionnaire"
            @update="updateQuestionnaire"
          />
        </v-card-text>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import { EventBus } from '@/utils/EventBus'
import generalMixin from '@/utils/generalMixin'
// import QuestionnaireForm from '@/components/forms/QuestionnaireFieldEdit/QuestionnaireForm.vue'
import fieldTypes from '@/enums/fieldTypes'
const _ = require('lodash')

export default {
  components: {
    // QuestionnaireForm
  },
  mixins: [generalMixin],
  props: {
    options: {
      type: Object,
      default: () => ({
        hasClose: false,
        inCase: false,
        inCaseModel: false,
        inTemplate: false,
        isVisibleDocuments: false,
        isVisibleSuggestions: true,
        mobile: false
      })
    },
    currentCaseForm: {
      type: Object,
      default: null
    },
    workbench: {
      type: Object,
      default: null
    },
    templateData: {
      type: Object,
      default: null
    },
    caseData: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      tab: null,
      error: null,
      loading: false,
      editor: null,
      templateEditorTab: 0,
      addNewFieldDialog: false,
      fieldTypes,
      inPreview: false,
      componentKey: 0,
      componentKeyAssistent: 0,
      actualTemplateData: {},
      selectedLanguage: null
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      settings: state => state.settings.settings,
      templates: state => state.templates.templates,
      actualSuggestionsEditorStorage: state => state.editorStore.actualSuggestionsEditorStorage
    }),
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    languages () {
      let activeLanguages = this.settings.activeLanguages
      return activeLanguages
    },
    actualRoute () {
      let route
      if (this.options.inCase) {
        route = `/cases/${this.$route.params._id}/${this.workbench._id}`
      }
      return route
    },
    suggestionsFromQuestionnaire () {
      let suggestions = []
      if (!this.options.inTemplate) {
        suggestions = this.workbench.templates.reduce((acc, temp) => {
          acc.push(
            ...temp.questionnaire.fields
          )
          return acc
        }, [])
      } else {
        suggestions = this.templateData.questionnaire.fields || []
      }
      return suggestions
    },
    documentSuggestionsFromQuestionnaire () {
      let suggestions = []
      for (let i = 0; i < this.suggestionsFromQuestionnaire.length; i++) {
        const suggestion = this.suggestionsFromQuestionnaire[i]
        if (suggestion.fieldType === 'file-input') {
          suggestions.push(suggestion)
        }
      }
      return suggestions
    },
    allSuggestions () {
      let suggestions = []
      if (!this.options.inTemplate) {
        suggestions = this.documentSuggestionsFromQuestionnaire.concat(this.workbench.requiredDocuments)
      }
      return suggestions
    },
    uniqueWorkbenchRequiredDocs () {
      console.log(this.options, 'OPTS')
      let requiredDocs = this.workbench.requiredDocuments.map((doc, index) => {
        const used = this.templateData.questionnaire.fields.find(f => f.id === doc.id)
        return {
          id: doc.id,
          name: doc.name,
          fieldType: doc.fieldType,
          fieldKey: doc.fieldKey,
          key: doc.key,
          inUse: !!used || false
        }
      })
      return requiredDocs || []

      // return this.allSuggestions.reduce((seed, current) => {
      //   const used = this.templateData.questionnaire.fields.find(f => f.id === current.id)
      //   if (used) {
      //     current.inUse = true
      //   } else {
      //     current.inUse = false
      //   }
      //   return Object.assign(seed, {
      //     [current.key]: current
      //   })
      // }, {})
    },
    suggestionsInUseInQuestionnaire () {
      let fieldNames = []
      if (this.currentCaseForm) {
        for (let i = 0; i < this.currentCaseForm.workbenches.length; i++) {
          const workbench = this.currentCaseForm.workbenches[i]
          for (let i = 0; i < workbench.templates.length; i++) {
            const template = workbench.templates[i]
            const fields = template.questionnaire.fields
            for (let i = 0; i < fields.length; i++) {
              const field = fields[i]
              let fieldName = field.name
              fieldNames.push(fieldName)
            }
          }
        }
      }
      if (this.caseData) {
        for (let i = 0; i < this.caseData.workbenches.length; i++) {
          const workbench = this.caseData.workbenches[i]
          for (let i = 0; i < workbench.templates.length; i++) {
            const template = workbench.templates[i]
            const fields = template.questionnaire.fields
            for (let i = 0; i < fields.length; i++) {
              const field = fields[i]
              let fieldName = field.name
              fieldNames.push(fieldName)
            }
          }
        }
      }
      if (this.options.inTemplate) {
        for (let i = 0; i < this.templates.length; i++) {
          const template = this.templates[i]
          const fields = template.questionnaire.fields
          for (let i = 0; i < fields.length; i++) {
            const field = fields[i]
            let fieldName = field.name
            fieldNames.push(fieldName)
          }
        }
      }
      return fieldNames
    },
    templateSuggestions () {
      const suggestions = Object.keys(this.settings.fields).reduce(
        (acc, key) => {
          const item = this.settings.fields[key]
          if (item.type !== 'file-input') {
            acc[key] = item
          }
          return acc
        },
        {}
      )
      return suggestions
    },
    actualTemplateAndQuestionairreSuggestions () {
      let editorSuggestions = []
      let filteredSuggestionsFromQuest = []
      let suggestions = []
      let finalArr = []
      if (this.actualSuggestionsEditorStorage.length) {
        this.actualSuggestionsEditorStorage.forEach(element => {
          editorSuggestions.push(element.attrs)
        })
        suggestions = _.uniqBy(editorSuggestions, 'fieldKey')
      }
      if (this.templateData.questionnaire.fields && this.templateData.questionnaire.fields.length) {
        const excludedDocumentSuggestions = this.templateData.questionnaire.fields.filter(field => field.fieldType !== 'file-input')
        filteredSuggestionsFromQuest = _.uniqBy(excludedDocumentSuggestions, 'fieldKey')
      }
      const mergedArr = [...suggestions, ...filteredSuggestionsFromQuest]
      finalArr = _.uniqBy(mergedArr, 'fieldKey')
      return finalArr
    },
    allFieldTags () {
      let inUse
      const reducedById = _.uniqBy(this.actualTemplateAndQuestionairreSuggestions, 'id')
      const fields = reducedById.map((f, index) => {
        const customHashtag = f.customHashtag
        if (f.customLabel) {
          inUse = this.templateData.questionnaire.fields.findIndex(field => field.fieldKey === f.fieldKey && field.customLabel === f.customLabel)
        } else {
          inUse = this.templateData.questionnaire.fields.findIndex(field => (field.fieldKey === f.fieldKey && !field.customLabel))
        }
        return {
          customHashtag,
          fieldType: f.fieldType,
          fieldKey: f.fieldKey,
          hint: '',
          id: f.id,
          customLabel: f.customLabel,
          // key: key,
          name: f.label ? f.label : f.name,
          inUse: inUse !== -1,
          language: f.language
        }
      })
      fields.forEach((_field, index) => {
        const personalSuggestion = this.getOwnSuggestionsWithInfoFromProfile(_field)
        if (personalSuggestion) {
          fields.splice(index, 1)
        }
      })
      return fields
      // return uniqueFields
      // let allFields = []
      // const filteredFieldsForCustomLabels = this.actualTemplateAndQuestionairreSuggestions.filter(sugg => sugg.customLabel !== null)
      // if (filteredFieldsForCustomLabels && filteredFieldsForCustomLabels.length) {
      //   allFields.push(...filteredFieldsForCustomLabels)
      // }
      // const reducedTemplateSuggestionsByKey = uniqBy(this.actualTemplateAndQuestionairreSuggestions, 'key') || []
      // const fields = reducedTemplateSuggestionsByKey.map((f, index) => {
      //   let key
      //   if (f.key) {
      //     key = f.key.toLowerCase()
      //   } else {
      //     key = f.label.toLowerCase().split(' ').join('_')
      //   }
      //   const customHashtag = f.customHashtag
      //   // const inUse = this.templateData.questionnaire.fields.findIndex(field => {
      //   //   if (field.key && key === field.key.toLowerCase()) {
      //   //     return f.attrs.customLabel === field.customLabel
      //   //   } else {
      //   //     return false
      //   //   }
      //   // })
      //   const inUse = this.templateData.questionnaire.fields.findIndex(field => field.key === f.key)
      //   return {
      //     customHashtag,
      //     fieldType: f.fieldType,
      //     hint: '',
      //     id: f.id,
      //     customLabel: f.customLabel,
      //     key: key,
      //     name: f.label ? f.label : f.name,
      //     inUse: inUse !== -1
      //   }
      // })
      // fields.forEach((_field, index) => {
      //   const personalSuggestion = this.getOwnSuggestionsWithInfoFromProfile(_field)
      //   if (personalSuggestion) {
      //     fields.splice(index, 1)
      //   }
      // })
      // // const uniqueFields = fields.reduce((acc, field) => {
      // //   const foundField = acc.find(i => i.key === field.key)
      // //   if (foundField) { // already in array
      // //     const foundWithCustomLabel = acc.find(i => i.customLabel === field.customLabel)
      // //     if (!foundWithCustomLabel) acc.push(field)
      // //   } else {
      // //     acc.push(field)
      // //   }
      // //   return acc
      // // }, [])
      // return [...fields, ...allFields]
    },
    rawTemplateDocs () {
      if (!this.templateData.questionnaire.fields) return []
      const fields = []
      for (let i = 0; i < this.templateData.questionnaire.fields.length; i++) {
        const field = this.templateData.questionnaire.fields[i]
        if (field.fieldType === 'file-input') {
          fields.push(field)
        }
      }
      return fields
    }
  },
  created () {
    EventBus.$on('suggestion-to-be-updated-in-editor', this.onFieldEvent)
    EventBus.$on('save-comment-in-template', this.saveTemplate)
  },
  beforeDestroy () {
    EventBus.$off('suggestion-to-be-updated-in-editor', this.onFieldEvent)
    EventBus.$off('save-comment-in-template', this.saveTemplate)
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      updateCaseWorkbenchTemplateSuggestion: 'cases/updateCaseWorkbenchTemplateSuggestion'
    }),
    ...mapMutations({
      toggleDrawer: 'app/toggleDrawer'
    }),
    onFieldEvent (field) {
      this.updateField(field)
    },
    openAddField () {
      this.addNewFieldDialog = true
    },
    onAddNewField (fieldType) {
      const field = {
        id: uuidv4(),
        name: null,
        hint: null,
        fieldType,
        fieldKey: null
      }
      this.addNewField(field)
    },
    async addNewField (field) {
      this.$emit('addField', field)
      this.addNewFieldDialog = false
    },
    useFieldInQuestionnaire (field) {
      if (field.inUse || this.suggestionsInUseInQuestionnaire.includes(field.name)) return
      const fieldIndex = this.templateData.questionnaire.fields.findIndex((f) => {
        if (f.fieldKey.toLowerCase() === field.fieldKey.toLowerCase()) {
          return f.customLabel === field.customLabel
        } else {
          return false
        }
      })
      if (fieldIndex !== -1) { // found
        return this.addToast({
          title: this.$t('warning|field_already_in_use'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
      delete field.inUse
      this.addNewField(field)
    },
    useAllDocumentFieldsInQuestionnaire () {
      if (this.uniqueWorkbenchRequiredDocs && Object.keys(this.uniqueWorkbenchRequiredDocs).length) {
        let fieldsToUpload = []
        for (const key in this.uniqueWorkbenchRequiredDocs) {
          if (Object.hasOwnProperty.call(this.uniqueWorkbenchRequiredDocs, key)) {
            const element = this.uniqueWorkbenchRequiredDocs[key]
            if (!element.inUse) {
              fieldsToUpload.push(element)
            }
          }
        }
        if (fieldsToUpload.length) this.$emit('addAllUnusedFields', fieldsToUpload)
        else {
          this.addToast({
            title: this.$t('warning|fields_already_in_use'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      }
    },
    useAllSuggestionsFieldsInQuestionnaire () {
      if (this.allFieldTags.length) {
        const fieldsToUpload = this.allFieldTags.filter(field => field.fieldType !== 'file-input' && !field.inUse)
        this.$emit('addAllUnusedFields', fieldsToUpload)
      }
    },
    async updateField (field) {
      const fieldIndex = this.templateData.questionnaire.fields.findIndex(
        f => f.id === field.id
      )
      const fieldIndexInTemplate = this.allFieldTags.findIndex(
        f => f.id === field.id
      )
      if (fieldIndexInTemplate !== -1) {
        this.allFieldTags[fieldIndexInTemplate] = field
        await this.$refs.editor.suggestionChangeInQuestionnaire(field)
        this.saveTemplate()
      }
      if (fieldIndexInTemplate === -1 && fieldIndex !== -1) {
        this.templateData.questionnaire.fields[fieldIndex] = field
        this.$emit('updateField', {
          _id: this.templateData._id,
          payload: this.templateData
        })
      }
    },
    async deleteField (field) {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('common|are_you_sure_you_want_to_delete_this_resource')
      })
      if (!res || !field) return
      this.$emit('deleteField', {
        fieldToBeDeleted: field
      })
    },
    async saveTemplate () {
      const questionnaireFields = this.templateData.questionnaire.fields.map(
        q => {
          const key = q.customHashtag
            ? q.name
              .toLowerCase()
              .split(' ')
              .join('_')
            : q.key
          return {
            ...q,
            key
          }
        }
      )
      this.templateData.questionnaire.fields = questionnaireFields
      const editorTemplateData = this.$refs.editor.getData()
      if (
        !editorTemplateData ||
        !editorTemplateData.json ||
        !editorTemplateData.html
      ) { return }
      this.$emit('save', {
        _id: this.templateData._id,
        payload: {
          templateData: editorTemplateData,
          questionnaire: this.templateData.questionnaire
        }
      })
    },
    updateQuestionnaire (questionairre) {
      this.$emit('updateQuestionnaire', questionairre)
    },
    async saveSuggestion (_id, workbenchId, templateId, suggestion) {
      try {
        const payload = {
          suggestion: suggestion,
          saveCustomizedLabel: true
        }
        await this.updateCaseWorkbenchTemplateSuggestion({ _id, workbenchId, templateId, payload })
      } catch (error) {
        console.error(error, 'error')
      }
    },
    updateTemplateName (template, type) {
      this.$emit('update-template-name', template, type)
    },
    updateTempDescription (template) {
      this.$emit('update-template-description', template)
    },
    getFieldType (fieldType) {
      switch (fieldType) {
      case 'text-input':
        return this.$t('fields|text-input')
      case 'date-input':
        return this.$t('fields|date-input')
      case 'currency-input':
        return this.$t('fields|currency-input')
      case 'file-input':
        return this.$t('fields|file-input')
      default:
        return this.$t('fields|text-input')
      }
    },
    refreshTab () {
      this.componentKey += 1
    },
    showMenu () {
      this.toggleDrawer(true)
    },
    getOwnSuggestionsWithInfoFromProfile (suggestion) {
      switch (suggestion.fieldKey) {
      case 'my_first_name':
        return true
      case 'my_last_name':
        return true
      case 'my_address_street':
        return true
      case 'my_address_city':
        return true
      case 'my_address_postal_code':
        return true
      case 'my_address_country':
        return true
      case 'client_first_name':
        return true
      case 'client_last_name':
        return true
      case 'client_date_of_birth':
        return true
      case 'client_place_of_birth':
        return true
      case 'client_address_street':
        return true
      case 'client_address_city':
        return true
      case 'client_address_postal_code':
        return true
      case 'client_address_country':
        return true
      case 'company_client_name':
        return true
      case 'company_client_type':
        return true
      case 'company_client_shared_capital':
        return true
      case 'company_client_selected_currency':
        return true
      case 'company_client_rcs_number':
        return true
      case 'company_client_place_of_registration':
        return true
      default:
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .btn {
    text-transform: none !important;
  }
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 5px;
  }
  .container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
  }
  .template-questionnaire-form {
    position: fixed;
    height: 100%;
    margin-right: 8px;
  }
  .template-questionnaire-form-case {
    position: relative;
    height: 100%;
  }
  .field-tags-container {
    display: flex;
    flex-wrap: wrap;
    .field-tag-add-box {
      display: flex;
      flex-wrap: nowrap;
      border: 1px solid rgba(0,0,0,0.1);
      border-radius: 3px;
      margin: .5rem;
      overflow: hidden;
      font-size: .7rem;
      line-height: normal;
      > div {
        display: flex;
        align-items: center;
        padding: 2px 2px;
        &:nth-of-type(2) {
          background-color: #91A6BC;
          color: white;
          cursor: pointer;
          &:hover {
            background-color: #5694d6;
          }
        }
      }
      &.used {
        > div {
          &:nth-of-type(2) {
            background-color: #2a9b9b;
            cursor: pointer;
            &:hover {
              background-color: #dd5a32;
            }
          }
        }
      }
    }
  }
</style>
